<template>
  <section v-if="loaded">
    <vue-headful :title="`FLPN Aviasolutions - ${pageTitle}`"></vue-headful>
    <h1 class="title">{{ pageTitle }}</h1>
    <b-tabs v-model="activeTab" @change="onChangeTab">
      <b-tab-item label="Aircraft">
        <aircraft :propFleets="fleetGroups" :flightPlan="flightPlan"></aircraft>
      </b-tab-item>
      <b-tab-item label="Flight">
        <FlightPlanning :flightPlan="flightPlan"></FlightPlanning>
      </b-tab-item>
      <b-tab-item label="Summary">
        <PlanSummary :flightPlan="flightPlan"></PlanSummary>
      </b-tab-item>
      <b-tab-item :label="'Report (Plan #'+flightPlan.plan.id+')'" v-if="flightPlan.plan && flightPlan.plan.id">
        <PlanReport :flightPlan="flightPlan" :result="result"></PlanReport>
      </b-tab-item>
      <b-tab-item :label="'Iterations Detail (Plan #'+flightPlan.plan.id+')'"
                  v-if="$auth.check(['admin']) && flightPlan.plan && flightPlan.plan.id">
        <IterationsDetail v-if="result.iteration_logs" :result="result"></IterationsDetail>
<!--        <IterationsDetailBasedOnNavLogs v-if="result && result.nav_logs"-->
<!--                                        :result="result"></IterationsDetailBasedOnNavLogs>-->
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import FleetsService from "../../services/fleets.service";
import Aircraft from "./Aircraft.vue";
import AircraftModel from "../../models/aircraft/aircraft.model";
import FlightPlanning from "./FlightPlanning.vue";
import PlanSummary from "./PlanSummary.vue";
import PlanReport from "./PlanReport.vue";
import PlansService from "../../services/plans.service";
import IterationsDetail from "./IterationsDetail.vue";
import IterationsDetailBasedOnNavLogs from "./IterationsDetailBasedOnNavLogs.vue";
import AircraftService from "../../services/aircrafts.service";
import {EventBus} from '../../event-bus.js';
import PlanHelper from "../../classes/plan/plan-helper.js";
import planResult from "@/store/modules/planResult";

export default {
  computed: {
    planResult() {
      return planResult
    }
  },
  data() {
    return {
      pageTitle: 'Plan',
      fleets: [],
      fleetGroups: [],
      fleetsService: new FleetsService(),
      planService: new PlansService(),
      aircraftService: new AircraftService(),
      planHelper: new PlanHelper(),
      activeTab: 0,
      flightPlan: {},
      plan: {},
      result: {
        alerts: [],
        user: null,
        iterations_details: []
      },
      loaded: false
    };
  },
  mounted: function () {
    this.fleetsService.selectors().then(
        ({data}) => {
          this.fleetGroups = data.fleet_groups;
          // this.flightPlan.aircraftTab.fleets = this.fleetGroups;
          // this.fleets = data.fleets;
          this.pageTitle = `Plan ${this.$route.params.id}`;
          const loadingComponent = this.$buefy.loading.open({container: null})
          this.planService.edit(this.$route.params.id).then(({data}) => {
                this.plan = data.data;
                this.result.user = data.data.user;
                this.result.iterations_details = data.data.iterations_details;
                this.result.iteration_logs = data.data.iteration_logs;
                this.result.alerts = data.data.alerts;
                this.result.nav_logs = data.data.nav_logs;

                this.flightPlan = this.planHelper.createEmptyFlightPlan();
                this.flightPlan.aircraftTab.fleets = this.fleetGroups;
                this.flightPlan.aircraftTab.fleet = this.getObjectFromGroup(this.fleetGroups, this.plan.aircraft.fleet_id);
                this.flightPlan.flightTab.alternateAirports.airports = [];
                this.flightPlan.validations.alternateAirports = [];
                this.flightPlan.flightTab.alternateAirports.display_alternate_navigation = this.plan.flight.display_alternate_navigation;
                this.flightPlan.flightTab.alternateAirports.display_alternate_header = this.plan.flight.display_alternate_header;
                this.flightPlan.aircraftTab.aircraft = new AircraftModel();
                this.flightPlan.flightTab.flight.route_id = this.plan.flight.route_id;
                this.flightPlan.flightTab.flight.flight_level = this.plan.flight.flight_level;
                this.flightPlan.flightTab.flight.route = this.plan.flight.route;
                this.flightPlan.validations.routeIsValid = true;
                this.flightPlan.validations.flightLevelIsValid = true;
                this.fleetsService.listAircrafts(this.plan.aircraft.fleet_id).then(
                    ({data}) => {
                      // AIRCRAFT
                      this.flightPlan.aircraftTab.aircrafts = AircraftModel.createMany(data.aircrafts);
                      this.flightPlan.aircraftTab.aircraft = this.getObjectById(
                          this.flightPlan.aircraftTab.aircrafts, this.plan.aircraft.aircraft_id
                      );
                      this.flightPlan.aircraftTab.versions = []
                      if (this.flightPlan.aircraftTab.aircraft) {
                        this.flightPlan.aircraftTab.versions = this.flightPlan.aircraftTab.aircraft.versions;
                      }

                      this.loaded = true;
                      this.flightPlan.aircraftTab.version = this.getObjectById(
                          this.flightPlan.aircraftTab.aircraft.versions,
                          this.plan.aircraft.version_id
                      );
                      loadingComponent.close();
                      this.getSpeedData()
                    },
                    () => {
                      loadingComponent.close();
                    }
                );
              },
              ({data}) => {
                loadingComponent.close();
                this.$buefy.toast.open({
                  message: 'An error occurred while trying to load a flight plan.',
                  type: 'is-danger'
                });
              });
        }
    );
  },
  components: {
    Aircraft,
    FlightPlanning,
    PlanSummary,
    PlanReport,
    IterationsDetail,
    IterationsDetailBasedOnNavLogs
  },
  watch: {
    'flightPlan.validations': {
      handler: function (val) {
        this.flightPlan.isValid = !(JSON.stringify(val).includes(`false`));
      },
      deep: true
    }
  },
  methods: {
    getObjectById(array, id) {
      // console.log('getObjectById', array, id)
      return array[array.map(function (x) {
        return x.id;
      }).indexOf(id)];
    },
    getObjectFromGroup(groups, id) {
      const entries = Object.entries(groups);
      const groupFound = entries.find((entry) => {
        return entry[1].find((e) => e.id === id)
      })
      if (groupFound) {
        return groupFound[1].find((f) => f.id === id);
      }
      return null;
    },
    onChangeTab(value) {
      if (value === 2) {
        EventBus.$emit(
            'onUpdatePlanSummary',
            {}
        );
      }
    },
    getSpeedData() {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.aircraftService.dataByEngWorkAndGear(
          this.flightPlan.aircraftTab.aircraft.id,
          {
            engWork: this.flightPlan.aircraftTab.aircraft.cantMotores || this.flightPlan.aircraftTab.aircraft.config_params.engine_qty || 2,
            gear: this.flightPlan.aircraftTab.aircraft.gear || 'U'
          }
      ).then(
          ({data}) => {
            loadingComponent.close();
            this.flightPlan.aircraftTab.dataByEngWorkAndGear = data.dataByEngWorkAndGear;
            EventBus.$emit(
                'aircraftSeleccionadoEnEdicion',
                {}
            );
            this.flightPlan.plan = this.plan;
          },
          () => {
            loadingComponent.close();
          }
      );
    }
  }
};
</script>
<style src="../../assets/css/print.css" media="print"></style>
<style>
.dangertab .ul .li .a {
  color: red;
  font-size: 50px;
}
</style>