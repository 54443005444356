<template>
  <section id="IterationsDetailBasedOnNavLogsSection">
    <div class="columns" v-for="(nav_log, index) in result.nav_logs">
      <div class="column is-12">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
            <span v-if="index == 0">Main Navigation Log</span>
            <span v-if="index != 0">Alternate Log # {{ index }}</span>
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <table class="table is-fullwidth is-hoverable">
                <thead>
                  <tr>
                    <th class="has-text-right">Iteration #</th>
                    <th class="has-text-right">Total Distance</th>
                    <th class="has-text-right">Distance to TOC</th>
                    <th class="has-text-right">Distance to TOD</th>
                    <th class="has-text-right">Initial Weight</th>
                    <th class="has-text-right">Fuel Weight</th>
                    <th class="has-text-right">Final Weight</th>
                    <th class="has-text-right">Fuel Difference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(iteration, index) in nav_log.iterations_detail">
                    <td class="has-text-right">{{index + 1}}</td>
                    <td class="has-text-right">{{iteration.total_distance}}</td>
                    <td class="has-text-right">{{iteration.distance_to_toc}}</td>
                    <td class="has-text-right">{{iteration.distance_to_tod}}</td>
                    <td class="has-text-right">{{iteration.initial_weight}}</td>
                    <td class="has-text-right">{{iteration.fuel_weight}}</td>
                    <td class="has-text-right">{{iteration.final_weight}}</td>
                    <td class="has-text-right">{{iteration.fuel_difference}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "IterationsDetailBasedOnNavLogs",
  props: ['result']
};
</script>
<style>
#IterationsDetailBasedOnNavLogsSection .card-header{
  background-color: lightgrey;
}
#IterationsDetailBasedOnNavLogsSection .card {
  min-height: 100%;
}
</style>
